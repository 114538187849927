import '@aws-amplify/ui-react/styles.css';
import Amplify, { Auth } from 'aws-amplify';
import Container from '@material-ui/core/Container';
import Header from './components/Header';
import React from 'react'
import Sidebar from './components/Sidebar';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import DashboardViewer from './components/DashboardViewer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LandingPage from './LandingPage';
import CookieConsent from "react-cookie-consent";
import DashboardManager from './components/admin/DashboardManager';
import FileImporter from './components/FileImporter';

Amplify.configure(awsconfig);

async function onSignOutClick() {
  console.log(Auth.signOut());
}

class App extends React.Component {

  componentDidCatch(error, info) {
    // Do something useful with error like logging to error reporting system// then force reload (if that's what you want):
    console.error('App catch - ' + error);
    window.location.reload(true);
  }

  render() {
    return (
      <div>
        <Authenticator
          className='data-amplify-authenticator'>

          {({ user, signOut }) => (
            <Container className='containerMain' maxWidth={false}>
              <Router>
                <div id='containerMain'>

                  <Sidebar userSignedIn={user} signOut={onSignOutClick} />
                  <Header />
                  <div id="page-wrap">
                    <Routes>
                      <Route path='/reports'
                        element={<DashboardViewer userSignedIn={user} />}
                      />
                      <Route path='/reports/manage'
                        element={<DashboardManager userSignedIn={user} />}
                      />
                      <Route path='/uploads'
                        element={<FileImporter userSignedIn={user} />}
                      />
                      <Route path='/'
                        element={<LandingPage userSignedIn={user} />}
                      />
                    </Routes>

                  </div>

                </div>

              </Router>


            </Container>
          )}
        </Authenticator>
        <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='wmca-aeb'
          buttonClasses='cookie-button'
          className='cookie'
          expires={7}
        >
          <h2>Cookies</h2>
          We use cookies to help tailor and improve our services and to measure their
          effectiveness. For more information please click <a className='cookie-link' rel="noopener noreferrer" href='https://www.wmca.org.uk/policies' target='_blank'>here</a>
        </CookieConsent>
      </div>
    );
  }
}

export default App;

