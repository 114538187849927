import React, { useState, useEffect } from 'react'
import Autocomplete from '../Autocomplete'
import { API, Auth } from 'aws-amplify';
import Dashboard from '../Dashboard';

const DashboardManager = () => {

    const [dashboards, setDashboards] = useState([])
    const [dashboardId, setDashboardId] = useState('')
    const [name, setName] = useState('')
    const [providerAvailable, setProviderAvailable] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [category, setCategory] = useState('')

    async function onSubmit() {
        var newCategory = ''

        try {
        newCategory = document.getElementById('autocompletetext').value
        } catch (e) {}

        if (!dashboardId) {
            alert('Please enter the report ID')
            return
        }

        if (!name) {
            alert('Please enter the report name')
            return
        }

        if (!newCategory) {
            setCategory('Not Specified')
        }

        const cognitoUser = await Auth.currentAuthenticatedUser();
        var currentSession = await Auth.currentSession();

        await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            if (err) {
                console.error(err)
                return
            }

        });

        currentSession = await Auth.currentSession();

        const params = {
            headers: {

            },
            response: true,
            queryStringParameters: {
                jwtToken: currentSession.idToken.jwtToken,
                payloadSub: currentSession.idToken.payload.sub,
                email: currentSession.idToken.payload.email,
            },
            body: {
                id: dashboardId,
                title: name,
                providerAvailable: (providerAvailable ? 1 : 0),
                category: newCategory,
                enabled: (enabled ? 1 : 0)
            }
        }

        const response = await API.post('quicksight', '/getQuickSightDashboardEmbedURL/reportList/dashboards/manage', params);

        console.log(response)

        onCancel()
    }

    const onCancel = () => {
        setDashboardId('')
        setName('')
        setProviderAvailable(false)
        setEnabled(false)
        setCategory('')
        try {
            document.getElementById('autocompletetext').value = '';
        } catch (e) { }
    }

    useEffect(() => {
        const getDashboards = async () => {
            await fetchDashboards()
        }


        getDashboards()

    }, [])

    async function fetchDashboards() {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        var currentSession = await Auth.currentSession();

        await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            if (err) console.error(err)
        });
        currentSession = await Auth.currentSession();

        const params = {
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: currentSession.idToken.jwtToken,
                payloadSub: currentSession.idToken.payload.sub,
                email: currentSession.idToken.payload.email
            }
        }
        const reportList = await API.get('quicksight', '/getQuickSightDashboardEmbedURL/getReportList', params);

        const sortedDashboards = [].concat(reportList.data.result)
            .sort((a, b) => a.title > b.title ? 1 : -1);

        setDashboards(sortedDashboards)

    }

    const getDistinctCategories = (data) => {
        const uniqueCategories = [];
        data.forEach(item => {
            if (uniqueCategories.indexOf(item.category) === -1) {
                uniqueCategories.push(item.category)
            }
        });
        return uniqueCategories
    }

    const changeDashboard = (dashboard) => {
        setCategory('')
        setDashboardId(dashboard.id)
        setName(dashboard.title)
        setProviderAvailable(dashboard.provider_available === 1 ? true : false)
        setEnabled(dashboard.enabled === 1 ? true : false)
        setCategory(dashboard.category)
    }

    return (
        <div className='dashboardViewContainer'>
            <div className='dashboardViewMenu dashboardMenu'>
                {dashboards.map((dashboard) => (
                    <Dashboard css={dashboardId === dashboard.id ? 'dashboard-active' : 'dashboard'} key={dashboard.id} dashboard={dashboard} onDashClick={() => changeDashboard(dashboard)} />
                ))
                }
            </div>
            <div className='dashboardViewManage'>
                <div className='add-form'>
                    <div className='form-control'>
                        <label>Report ID</label>
                        <input
                            type='text'
                            className='text-addform'
                            placeholder='36h6f6cf-y564-4365-ue8o-854iy98b48rd'
                            value={dashboardId}
                            onChange={(e) => setDashboardId(e.target.value)}
                        />
                    </div>
                    <div className='form-control'>
                        <label>Report Name</label>
                        <input
                            type='text'
                            className='text-addform'
                            placeholder='Enrolments and Funding'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='form-control'>
                        <label htmlFor='' >Category</label>
                        <Autocomplete
                            userInput1={category}
                            suggestions={[].concat(getDistinctCategories(dashboards))}
                            onChange={(e) => setCategory(e.target.value)}
                        />
                    </div>

                    <div className='form-control form-control-check'>
                        <label>Enable this report for use</label>
                        <input
                            type='checkbox'
                            checked={enabled}
                            value={enabled}
                            onChange={(e) => setEnabled(e.currentTarget.checked)}
                        />
                    </div>
                    <div className='form-control form-control-check'>
                        <label>Is this report available to providers?</label>
                        <input
                            type='checkbox'
                            checked={providerAvailable}
                            value={providerAvailable}
                            onChange={(e) => setProviderAvailable(e.currentTarget.checked)}
                        />
                    </div>

                    <button className='btn save' onClick={onSubmit}>Save Report</button>
                    <button className='btn cancel' onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DashboardManager