import React, { useEffect, useState } from 'react'
import { Dashboard } from './Dashboard'
import Embed from '../Embed'
import { API, Auth } from 'aws-amplify';

function DashboardViewer({ userSignedIn }) {

  const [providerName, setProviderName] = useState('')
  const username = userSignedIn.signInUserSession.idToken.payload["custom:name"]
  const [dashboardId, setDashboardId] = useState('')
  const [token, setToken] = useState('')
  const [sub, setSub] = useState('')
  const [email, setEmail] = useState('')
  const [displayDashboard, setDisplayDashboard] = useState(false)
  const [report, setReport] = useState('')
  const [title, setTitle] = useState('')
  const userRole = userSignedIn.signInUserSession.accessToken.payload["cognito:groups"]

  const changeDashboard = async (dashboard) => {

    setDisplayDashboard(false);
    setReport(dashboard.title);
    const cognitoUser = await Auth.currentAuthenticatedUser();

    var currentSession = await Auth.currentSession();

    await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) console.error(err)
    });
    currentSession = await Auth.currentSession();

    setToken(currentSession.idToken.jwtToken);
    setSub(currentSession.idToken.payload.sub);
    setEmail(currentSession.idToken.payload.email);
    setTitle(dashboard.title);
    setDashboardId(dashboard.id);

    setDisplayDashboard(true);
  }

  const [dashboards, setDashboards] = useState([])

  useEffect(() => {
    const getDashboards = async () => {
      const dashboardsFromServer = await fetchDashboards()
      setDashboards(dashboardsFromServer)
    }

    getDashboards()
  }, [])

  const fetchDashboards = async () => {

    var currentSession = await Auth.currentSession();

    const params = {
      headers: {},
      response: true,
      queryStringParameters: {
        jwtToken: currentSession.idToken.jwtToken,
        payloadSub: currentSession.idToken.payload.sub,
        email: currentSession.idToken.payload.email
      }
    }
    const reportList = await API.get('quicksight', '/getQuickSightDashboardEmbedURL/getReportList', params);
    var results = reportList.data.result
    var categories = getDistinctCategories(results)
    var newList = getCategorisedMenu(categories, results)

    return newList
  }

  useEffect(() => {
    const getOrganisation = () => {

      if ("custom:organisation" in userSignedIn.signInUserSession.idToken.payload) {
        const orgFound = userSignedIn.signInUserSession.idToken.payload["custom:organisation"]
        setProviderName(orgFound)
      }
      else {
        setProviderName('')
      }


    }

    getOrganisation()
  }, [])

  const getDistinctCategories = (data) => {
    const uniqueCategories = [];

    if (userRole[0] === 'Admin' || userRole[0] === 'SDO') {
      data.forEach(dash => {
        if (dash.enabled === 1 && dash.provider_available === 0) {
          if (uniqueCategories.indexOf(dash.category) === -1) {
            uniqueCategories.push(dash.category)
          }

        }
      })
    }


    if (userRole[0] === 'Provider') {
      data.forEach(dash => {
        if (dash.enabled === 1 && dash.provider_available === 1) {
          if (uniqueCategories.indexOf(dash.category) === -1) {
            uniqueCategories.push(dash.category)
          }

        }
      })
    }

    return uniqueCategories
  }

  const getCategorisedMenu = (categories, data) => {
    var newList = []
    categories.forEach(category => {
      var newListItems = data.filter(obj => {
        return obj.category === category;
      });

      var dataEntry = {
        'header': category,
        'menu': newListItems
      }

      newList.push(dataEntry)

    })

    return newList
  }

  return (
    <div className='dashboardViewContainer'>
      <span className='DVTitle'>{title}</span>

      <div className='dashboardView'>
        {userRole.length > 0 && (
          <div className='dashboardViewMenu'>
            {userRole.includes('Admin') && (
              dashboards.map(item => (
                <>
                  <h3 className='dashboard-header'>{item.header}</h3>
                  {item.menu.filter(dash => dash.provider_available === 0 && dash.enabled === 1)
                    .map((dashboard) => (
                      <Dashboard css='dashboard' key={dashboard.id} dashboard={dashboard} onDashClick={() => changeDashboard(dashboard)} />
                    ))
                  }
                </>
              ))
            )
            }

            {userRole.includes('SDO') && (
              dashboards.map(item => (
                <>
                  <h3 className='dashboard-header'>{item.header}</h3>
                  {item.menu.filter(dash => dash.provider_available === 0 && dash.enabled === 1)
                    .map((dashboard) => (
                      <Dashboard css='dashboard' key={dashboard.id} dashboard={dashboard} onDashClick={() => changeDashboard(dashboard)} />
                    ))
                  }
                </>
              ))

            )
            }

            {userRole.includes('Provider') && (
              dashboards.map(item => (
                <>
                  <h3 className='dashboard-header'>{item.header}</h3>
                  {item.menu.filter(dash => dash.provider_available === 1 && dash.enabled === 1)
                    .map((dashboard) => (
                      <Dashboard css='dashboard' key={dashboard.id} dashboard={dashboard} onDashClick={() => changeDashboard(dashboard)} />
                    ))
                  }
                </>
              ))
            )
            }
          </div>
        )}
        <div id='dashboardViewEmbed' className='dashboardViewEmbed'>


          {displayDashboard && (
            <Embed DashboardId={dashboardId} token={token} sub={sub} email={email} providerName={providerName}
              reportclicked={true} reportname={report} username={username} userGroup={userRole}
            />
          )
          }
        </div>
      </div>
    </div>
  )
}

export default DashboardViewer