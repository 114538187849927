import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {slide as Menu} from 'react-burger-menu';
import { IconContext } from 'react-icons';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as SiIcons from 'react-icons/si';
import * as RiIcons from 'react-icons/ri';
import logo from './images/wmca-white.png';

const Sidebar = ({ userSignedIn }) => {

    let navigate = useNavigate();

    async function onSignOutClick() {
        navigate("/", { replace: true });
        console.log(Auth.signOut());
    }

    const [userRole, setUserRole] = useState([]);

    useEffect(() => {
        const getUserRoles = () => {
            if ("cognito:groups" in userSignedIn.signInUserSession.accessToken.payload) {
                const userRolesFound = userSignedIn.signInUserSession.accessToken.payload["cognito:groups"]
                setUserRole(userRolesFound)
            }
            else {
                setUserRole([])
            }

        }

        getUserRoles()
    }, [])

    return (
        <Menu>
            <IconContext.Provider value={{ color: '#fff' }}>
                <nav className={'nav-menu active'}>
                    <ul className='nav-menu-items'>

                        <li className='navbar-toggle'>
                            <a href="https://www.wmca.org.uk/" target='_blank' rel="noopener noreferrer" className='menu-bars'>
                                <img src={logo} alt="West Midlands Combined Authority" />
                            </a>
                        </li>


                        <li className={window.location.pathname === '/' ? 'nav-text-active' : 'nav-text'}>

                            <Link id="m_home" to="/">
                                <AiIcons.AiFillHome />
                                <span>Home</span>
                            </Link>

                        </li>

                        {userRole.length > 0 &&
                            <li className={window.location.pathname === '/reports' ? 'nav-text-active' : 'nav-text'}>
                                <Link id="m_dashboards" to="/reports">
                                    <SiIcons.SiGoogleanalytics />
                                    <span>Reports</span>
                                </Link>
                            </li>
                        }
                        {userRole.includes('Admin') &&
                            <li  className={window.location.pathname === '/reports/manage' ? 'nav-text-active' : 'nav-text'}>

                                <Link id="m_dashboard" to="/reports/manage">
                                    <RiIcons.RiEditBoxFill />
                                    <span>Edit Reports</span>
                                </Link>

                            </li>
                        }
                        {userRole.includes('Admin') &&
                            <li className={window.location.pathname === '/uploads' ? 'nav-text-active' : 'nav-text'}>

                                <Link id="m_upload" to="/uploads">
                                    <FaIcons.FaUpload />
                                    <span>Upload Files</span>
                                </Link>

                            </li>
                        }
                        <li className='nav-text' >
                            <button className='nav-text.a menu-button' onClick={onSignOutClick} >
                                <span><FaIcons.FaSignOutAlt />
                                    <span>
                                        Sign Out
                                    </span>
                                </span>
                            </button>
                        </li>
                        <li className='nav-text-footer'>
                        <span>&copy; {new Date().getFullYear()} West Midlands</span>
                        <br />
                        <span>Combined Authority</span>
                        </li>
                    </ul>
                    
                </nav>
                
            </IconContext.Provider>
            </Menu>
    )

}

export default Sidebar