import React from 'react';
import { API, Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const useStyles = theme => ({
    loading: {
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: theme.spacing(4),
    },
});

class Embed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            jwttoken: ''
        };

    }

    componentDidCatch(error, info) {
        // Do something useful with error like logging to error reporting system// then force reload (if that's what you want):
        console.log('Embed catch - ' + error);
        window.location.reload(true);
    }

    componentDidMount() {
        this.setState({ jwtToken: this.props.token });
        //console.log('Embed is rendered')
        this.getQuickSightDashboardEmbedURL();
    }

    getQuickSightDashboardEmbedURL = async () => {
        const jwtToken = this.props.token;
        const payloadSub = this.props.sub;
        const email = this.props.email;
        const providerName = this.props.providerName;
        var queryStringParameters = {}

        if (!this.props.reportclicked) {
            const data = await Auth.currentSession();
            queryStringParameters = {
                jwtToken: data.idToken.jwtToken,
                payloadSub: data.idToken.payload.sub,
                email: data.idToken.payload.email,
                DashboardId: this.props.DashboardId,
                reportclicked: false
            }
        } else {
            queryStringParameters = {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                DashboardId: this.props.DashboardId,
                reportclicked: true,
                username: this.props.username,
                reportname: this.props.reportname,
                usergroup: this.props.userGroup[0]

            }
        }
        //console.log(queryStringParameters)

        const params = {
            headers: {},
            response: true,
            queryStringParameters
        }
        //console.log(params)
        const quicksight = await API.get('quicksight', '/getQuickSightDashboardEmbedURL', params);
        //console.log('This is inside the getQuickSightDashboardEmbedURL');
        //console.log(quicksight);
        const containerDiv = document.getElementById("dashboardContainer");

        const options = {
            url: quicksight.data.data.EmbedUrl,
            container: containerDiv,
            parameters: {
                Provider: providerName
            },
            scrolling: "no",
            height: "100%",
            width: "100%",
            footerPaddingEnabled: false,
            iframeResizeOnSheetChange: false,
            printEnabled: false,
            undoRedoDisabled: false,
            resetDisabled: true

        };
        try {
            QuickSightEmbedding.embedDashboard(options);
        } catch (e) {

        }
        this.setState({ loader: false });

    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.loader && (
                    <div className={classes.loading}> <CircularProgress /> </div>
                )}
                <div id="dashboardContainer"></div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Embed);