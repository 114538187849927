import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';

const FileImporter = ({ userSignedIn }) => {

    const [userRole, setUserRole] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const getUserRoles = () => {
            if ("cognito:groups" in userSignedIn.signInUserSession.accessToken.payload) {
                const userRolesFound = userSignedIn.signInUserSession.accessToken.payload["cognito:groups"]
                setUserRole(userRolesFound)
            }
            else {
                setUserRole([])
            }

        }
        getUserRoles()
    }, [])

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0])
    }

    async function handleFileUpload() {

        console.log(selectedFile)

        const cognitoUser = await Auth.currentAuthenticatedUser();
        var currentSession = await Auth.currentSession();
        let bodyData = new FormData();

        bodyData.append('file',
            selectedFile)

        await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            if (err) {
                console.error(err)
                return
            }

        });

        currentSession = await Auth.currentSession();

        const params = {
            headers: {

            },
            response: true,
            queryStringParameters: {
                jwtToken: currentSession.idToken.jwtToken,
                payloadSub: currentSession.idToken.payload.sub,
                email: currentSession.idToken.payload.email,
            },
            body: {
                data: bodyData
            }
        }

        console.log(params)

        const response = await API.post('quicksight', '/getQuickSightDashboardEmbedURL/upload', params);

        console.log(response)
    }

    return (
        <div>
            {userRole.includes('Admin') && (
                <div>
                    <input type='file' onChange={handleFileInput} />
                    <button onClick={handleFileUpload()} >Click Me</button>
                </div>
            )}
        </div>
    )
}

export default FileImporter