import React from 'react'

export const Dashboard = ({ dashboard, onDashClick, css }) => {

    return (
        <div 
        className={css}
        onClick={onDashClick}
        >
                <h4 >{dashboard.title}</h4>

        </div>


    )
}

export default Dashboard
