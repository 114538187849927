import React, { useState, useEffect } from 'react';
import Embed from './Embed';

function LandingPage({ userSignedIn }) {

    const [userRole, setUserRole] = useState([]);
    const username = userSignedIn.signInUserSession.idToken.payload["custom:name"]
    const [organisation, setOrganisation] = useState('')


    useEffect(() => {
        const getUserRoles = () => {

            //console.log(userSignedIn.signInUserSession.idToken.payload["custom:name"])
            
            if ("cognito:groups" in userSignedIn.signInUserSession.accessToken.payload) {
                const userRolesFound = userSignedIn.signInUserSession.accessToken.payload["cognito:groups"]
                setUserRole(userRolesFound)
            }
            else {
                setUserRole([])
            }


        }

        getUserRoles()
    }, [])

    useEffect(() => {
        const getOrganisation = () => {
            
            if ("custom:organisation" in userSignedIn.signInUserSession.idToken.payload) {
                const orgFound = userSignedIn.signInUserSession.idToken.payload["custom:organisation"]
                setOrganisation(orgFound)
            }
            else {
                setOrganisation('')
            }


        }

        getOrganisation()
    }, [])



    return (
        <div>

            {(userRole.length <= 0) &&
                <div>
                    <h1>You do not have a User Group assigned yet</h1>
                    <h1>Please contact the AEB Data Team to be assigned one</h1>

                </div>
            }

            {userRole.includes('Admin') && (
                <div className='dashboardViewContainer'>
                    <Embed DashboardId='3d61bdb7-64d7-4936-afaa-6aafa55b6899'
                        reportclicked={false} reportname='Enrolments and Funding - All Returns' username={username} userGroup={userRole}
                    />
                </div>
            )

            }

            {userRole.includes('SLT') && (
                <></>
            )

            }

            {userRole.includes('SDO') && (
                <div className='dashboardViewContainer'>
                    <Embed DashboardId='2115b7b0-fe38-4732-a8ab-93e78a28cd16'
                        reportclicked={false} reportname='Providers Summary' username={username} userGroup={userRole}
                    />
                </div>
            )

            }

            {userRole.includes('Provider') && (
                <div className='dashboardViewContainer'>
                    <Embed DashboardId='d1cc7bed-762c-40fe-a797-c73c299b8dfc' providerName={organisation}
                        reportclicked={false} reportname='Portal Homepage' username={username} userGroup={userRole}
                    />
                </div>

            )

            }


        </div>

    )
}


export default LandingPage